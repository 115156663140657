<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :elevation="hover ? 6 : 2"
      :ripple="false"
      height="160px"
      :width="$vuetify.breakpoint.mobile ? '400px' : '300px'"
      class="mt-3"
      :class="$vuetify.breakpoint.mobile ? 'mx-auto' : ''"
      @click="openOverview"
    >
      <v-row class="ml-1">
        <v-col
          cols="10"
        >
          <div
            v-if="template.name.length >= 20"
            class="thick"
          >
            <v-tooltip
              top
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ template.name.slice(0, 15) + '...' }}
                </div>
              </template>
              <span>{{ template.name }}</span>
            </v-tooltip>
          </div>
          <div
            v-else
            class="thick"
          >
            {{ template.name }}
          </div>
          <div class="template-data mt-n1">
            <v-icon
              small
              color="primary"
            >
              {{ icons.template }}
            </v-icon>
            {{ $t('templates|template') }}
          </div>
          <div
            v-if="template.category === 'none'"
            class="template-data"
          >
            {{ $t('common|category') }}: {{ $t('common|uncategorized') }}
          </div>
          <div
            v-else
            class="template-data"
          >
            {{ $t('common|category') }}: {{ template.category }}
          </div>
          <div
            v-if="template.description.length >= 20"
            class="template-data"
          >
            <v-tooltip
              top
              color="primary"
              max-width="400"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('common|description') }}: {{ template.description.slice(0, 20) + '...' }}
                </div>
              </template>
              <span>{{ template.description }}</span>
            </v-tooltip>
          </div>
          <div
            v-else
            class="template-data"
          >
            {{ $t('common|description') }}: {{ template.description }}
          </div>
        </v-col>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              x-small
              v-bind="attrs"
              style="position: absolute; top: 15px; right: 25px;"
              v-on="on"
              @click.stop="removePinFromDashboard(template, location)"
            >
              <v-icon>
                mdi-pin
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('actions|remove_from_pinboard') }}</span>
        </v-tooltip>
      </v-row>
      <v-btn
        text
        color="deep-purple accent-4"
        link
        x-small
        style="position: absolute; bottom: 7px; left: 10px;"
        @click.stop="template.shared && account.accountType !== 'god' ? previewTemplate(template) : viewTemplate(template)"
      >
        {{ template.shared && account.accountType !== 'god' ? 'Preview' : $t('actions|open') }}
      </v-btn>
      <v-btn
        v-if="account.accountType === 'god'"
        text
        icon
        x-small
        style="position: absolute; bottom: 8px; right: 25px;"
        color="red accent-4"
        @click.stop="deleteTemplateAction(template)"
      >
        <v-icon>
          {{ icons.trash }}
        </v-icon>
      </v-btn>
      <v-btn
        v-if="account.accountType !== 'god' && !template.shared"
        text
        icon
        x-small
        style="position: absolute; bottom: 8px; right: 25px;"
        color="red accent-4"
        @click.stop="getTemplateSharedWithLength(template) <= 1 ? deleteTemplateAction(template) : deleteActionTemplateCardMini(template)"
      >
        <v-icon>
          {{ icons.trash }}
        </v-icon>
      </v-btn>
      <v-flex
        xs2
        md1
        lg1
      >
        <v-sheet
          color="primary"
          class="delete stripe-position mr-2 my-auto"
          min-width="9"
          max-width="10"
          height="80%"
        />
      </v-flex>
    </v-card>
  </v-hover>
</template>

<script>
import { mdiTrashCanOutline, mdiTextBoxOutline } from '@mdi/js'
import TemplateCardMixin from './TemplateCardMixin'

export default {
  mixins: [TemplateCardMixin],
  data () {
    return {
      icons: {
        trash: mdiTrashCanOutline,
        template: mdiTextBoxOutline
      },
      location: 'dashboard'
    }
  }
}
</script>

<style scoped>
.actions-position {
  position: absolute;
  bottom: 0px;
}
.stripe-position {
  position: absolute;
  bottom: 15px;
}
.delete {
  position: absolute;
  right: 0;
}
.thick {
  font-size: 15px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
}
.template-data {
  font-size: 14px;
  font-weight: lighter;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 260px;
  overflow: hidden;
}
/* .template-data:hover {
  overflow: visible;
} */
</style>
