<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :elevation="hover ? 6 : 2"
      :ripple="false"
      height="160px"
      width="300px"
      class="mt-3"
    >
      <v-row class="ml-1">
        <v-col
          cols="10"
        >
          <div
            v-if="documentData.raw.originalname.length >= 20"
            class="thick"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ documentData.raw.originalname.slice(0, 15) + '...' }}
                </div>
              </template>
              <span>{{ documentData.raw.originalname }}</span>
            </v-tooltip>
          </div>
          <div
            v-else
            class="thick"
          >
            {{ documentData.raw.originalname }}
          </div>
          <div class="document-data mt-n1 d-flex align-center">
            <v-icon
              v-if="isIcon(documentData.raw.mimetype, documentData.raw.originalname)"
              :color="getItemColor(documentData.raw.mimetype)"
              small
              class="mr-2"
            >
              {{ getIconType(documentData.raw.mimetype, documentData.raw.originalname) }}
            </v-icon>
            <img
              v-if="documentData.raw.mimetype === 'application/vnd.apple.pages' || documentData.raw.mimetype === 'application/vnd.apple.numbers'"
              :src="getSvg(documentData.raw.mimetype, documentData.raw.originalname)"
              height="16px"
              width="auto"
              class="mr-2"
            >
            <img
              v-if="documentData.raw.mimetype === 'application/octet-stream' && !isIcon(documentData.raw.mimetype, documentData.raw.originalname)"
              :src="getOctetValue(documentData.raw.originalname)"
              height="16px"
              width="auto"
              class="mr-2"
            >
            {{ $t('documents|document') }}
          </div>
          <div
            class="document-data"
          >
            {{ $t('common|type') }}: {{ documentData.info.document_type }}
          </div>
        </v-col>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              x-small
              v-bind="attrs"
              style="position: absolute; top: 15px; right: 25px;"
              v-on="on"
              @click.stop="removePinFromDashboard(documentData)"
            >
              <v-icon>
                mdi-pin
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('actions|remove_from_pinboard') }}</span>
        </v-tooltip>
      </v-row>
      <v-btn
        text
        x-small
        color="deep-purple accent-4"
        style="position: absolute; bottom: 7px; left: 10px;"
        @click="openFile(documentData)"
      >
        {{ $t('actions|open') }}
      </v-btn>
      <v-spacer />
      <v-btn
        text
        icon
        x-small
        color="red accent-4"
        style="position: absolute; bottom: 8px; right: 25px;"
        @click="deleteDocumentAction(documentData, 'from-document-card')"
      >
        <v-icon v-text="icons.trash" />
      </v-btn>
      <v-flex
        xs2
        md1
        lg1
      >
        <v-sheet
          color="secondary"
          class="delete stripe-position mr-2 my-auto"
          min-width="9"
          max-width="10"
          height="80%"
        />
      </v-flex>
      <FileView
        ref="fileView"
      />
    </v-card>
  </v-hover>
</template>

<script>
import { mapState } from 'vuex'
import { mdiTrashCanOutline } from '@mdi/js'
import DocumentMixin from './DocumentMixins/DocumentMixin'
import FileView from '../../components/dialogs/FileView.vue'

export default {
  components: { FileView },
  mixins: [DocumentMixin],
  props: {
    documentData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        trash: mdiTrashCanOutline
      }
    }
  },
  computed: {
    // account and company needed in mixin
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    })
  }
}
</script>

<style scoped>
.actions-position {
  position: absolute;
  bottom: 0px;
}
.stripe-position {
  position: absolute;
  bottom: 15px;
}
.delete {
  position: absolute;
  right: 0;
}
.thick {
  font-size: 15px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
}
.document-data {
  font-size: 14px;
  font-weight: lighter;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 260px;
  overflow: hidden;
}
.document-data:hover {
  overflow: visible;
}
</style>
