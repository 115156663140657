<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :elevation="hover ? 6 : 2"
      :ripple="false"
      :disabled="disabled"
      height="160px"
      width="300px"
      class="mt-3"
      @click="openOverview(client)"
    >
      <v-row class="mt-1 ml-1">
        <v-col
          cols="10"
        >
          <div
            v-if="clientName.length>=20"
            class="thick"
          >
            {{ clientName.slice(0, 15) + '...' }}
          </div>
          <div
            v-else
            class="thick"
          >
            {{ clientName }}
          </div>
        </v-col>
      </v-row>
      <div class="client-data ml-3 mt-2">
        <v-icon
          color="primary"
          small
        >
          {{ icons.client }}
        </v-icon>
        {{ $t('clients|client') }}
      </div>
      <div
        class="client-data ml-3"
      >
        {{ $t('common|email') }}: {{ client.clientData.email }}
      </div>
      <div class="client-data ml-3 mt-n1">
        {{ $t('clients|client_type') }}: {{ client.clientType }}
      </div>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            x-small
            style="position: absolute; top: 15px; right: 25px;"
            v-on="on"
            @click.stop="removePinFromDashboard(client)"
          >
            <v-icon>
              mdi-pin
            </v-icon>
          </v-btn>
        </template>
        <span>Remove from pinboard</span>
      </v-tooltip>
      <v-btn
        text
        color="deep-purple accent-4"
        link
        x-small
        :to="`/clients/${client._id}`"
        style="position: absolute; bottom: 7px; left: 10px;"
        @click.stop
      >
        {{ $t('actions|open') }}
      </v-btn>

      <v-btn
        text
        icon
        x-small
        style="position: absolute; bottom: 8px; right: 25px;"
        color="red accent-4"
        @click.stop="deleteClient(client)"
      >
        <v-icon v-text="icons.trash" />
      </v-btn>
      <v-col
        cols="1"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              :attrs="attrs"
              :color="client.realClient ? 'success' : 'grey'"
              class="delete stripe-position mr-2 my-auto"
              min-width="9"
              max-width="10"
              height="80%"
              v-on="on"
            />
          </template>
          <span>{{ client.realClient ? 'Client with lavvira account' : 'Locally created client profile' }}</span>
        </v-tooltip>
      </v-col>
    </v-card>
  </v-hover>
</template>

<script>
import { mdiTrashCanOutline, mdiAccountCircleOutline } from '@mdi/js'
import ClientCardMixin from './ClientMixins/ClientCardMixin'

export default {
  mixins: [ClientCardMixin],
  data () {
    return {
      icons: {
        trash: mdiTrashCanOutline,
        client: mdiAccountCircleOutline
      }
    }
  }
}
</script>

<style scoped>
.actions-position {
  position: absolute;
  bottom: 0px;
}
.stripe-position {
  position: absolute;
  bottom: 15px;
}
.delete {
  position: absolute;
  right: 0;
}
.thick {
  font-size: 15px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
}
.client-data {
  font-size: 14px;
  font-weight: lighter;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 170px;
  overflow: hidden;
}
.client-data:hover {
  overflow: visible;
}
</style>
