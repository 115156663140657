<template>
  <v-dialog
    v-model="lavviraTemplatePreviewDialog"
    width="800"
  >
    <v-card
      v-if="lavviraTemplatePreviewDialog"
      class="px-2"
    >
      <v-card-title class="pt-4">
        Lavvira template preview
      </v-card-title>
      <v-spacer />
      <v-icon
        class="x-icon-position"
        @click="resetAndClose()"
      >
        mdi-close
      </v-icon>
      <v-row no-gutters>
        <v-col
          cols="12"
          class="pa-8"
        >
          <div
            class="editor-overview mt-n4 pa-8"
          >
            <editor-content
              :editor="editor"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { Editor, EditorContent, VueRenderer } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import FontFamily from '@tiptap/extension-font-family'
import { Color } from '@/tiptap/extensions/colorStyle'
import Highlight from '@tiptap/extension-highlight'
import Typography from '@tiptap/extension-typography'
import Paragraph from '@tiptap/extension-paragraph'
import Underline from '@tiptap/extension-underline'
import HashTag from '../../pages/TemplateEditor/HashTag/HashTag'
import Comment from '../../pages/TemplateEditor/Comment/Comment'
import FieldTagDialog from '../../pages/TemplateEditor/FieldTagDialog.vue'

export default {
  components: {
    EditorContent
  },
  props: {
    templateData: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      lavviraTemplatePreviewDialog: false,
      editor: null
    }
  },

  mounted () {
    const editor = new Editor({
      extensions: [
        StarterKit,
        TextAlign,
        TextStyle,
        FontFamily,
        Color,
        Highlight,
        Typography,
        TableRow,
        TableHeader,
        Paragraph.extend({
          addAttributes () {
            return {
              color: {
                default: null,
                // Take the attribute values
                renderHTML: attributes => {
                  // … and return an object with HTML attributes.
                  return {
                    style: `color: ${attributes.color}`
                  }
                }
              },
              fontSize: {
                default: '11px',
                renderHTML: attributes => {
                  // … and return an object with HTML attributes.
                  return {
                    style: `font-size: ${attributes.fontSize}`
                  }
                }
              },
              lineHeight: {
                default: '1.2',
                renderHTML: attributes => {
                  // … and return an object with HTML attributes.
                  return {
                    style: `line-height: ${attributes.lineHeight}`
                  }
                }
              },
              fontFamily: {
                default: 'Sans-Serif',
                renderHTML: attributes => {
                  // … and return an object with HTML attributes.
                  return {
                    style: `font-family: ${attributes.fontFamily}`
                  }
                }
              }
            }
          }
        }),
        TableCell.extend({
          addAttributes () {
            return {
              ...this.parent?.(),
              backgroundColor: {
                default: null,
                // Customize the HTML parsing (for example, to load the initial content)
                parseHTML: element => element.getAttribute('table-background-color'),
                // … and customize the HTML rendering.
                renderHTML: attributes => {
                  return {
                    'table-background-color': attributes.backgroundColor,
                    style: `background-color: ${attributes.backgroundColor}`
                  }
                }
              }
            }
          }
        }),
        Table,
        Highlight.configure({
          multicolor: true
        }),
        Underline.configure({
          HTMLAttributes: {
            class: 'underline'
          }
        }),
        Comment.configure({
          template: this.template,
          HTMLAttributes: {
            class: 'comment'
          }
        }),
        HashTag.configure({
          questionairre: this.questionairre,
          relatedDocuments: this.relatedDocuments,
          caseData: this.caseData,
          workbench: this.workbench,
          templateId: this.templateId,
          edit: this.edit,
          refreshQuestionairreFields: this.refreshQuestionairreFields,
          saveSuggestion: this.saveSuggestion,
          HTMLAttributes: {
            class: 'mention'
          },
          suggestion: {
            render: (...args) => {
              let component
              return {
                onStart: props => {
                  component = new VueRenderer(FieldTagDialog, {
                    parent: this,
                    propsData: {
                      ...props,
                      value: true,
                      language: this.language,
                      onSelect: this.selectSuggestion,
                      templateFieldTags: this.templateFieldTags
                    }
                  })
                },
                onExit () {
                  component.destroy()
                }
              }
            }
          }
        })
      ]
    })
    this.editor = editor
    this.editor.setOptions({ editable: false })
    this.editor.commands.setContent(this.templateData.json)
  },

  updated () {
    this.editor.commands.setContent(this.templateData.json)
  },

  async beforeDestroy () {
    this.editor.destroy()
  },

  methods: {
    resetAndClose () {
      this.lavviraTemplatePreviewDialog = false
    }
  }
}
</script>

<style scoped>
.x-icon-position {
  position: absolute;
  top: 12px;
  right: 15px;
}
.editor-overview {
  height: 78vh;
  background: #fffffe;
  border: 1px solid #b1afaf;
  border-radius: 0.1rem;
  margin: 1rem 0;
  position: relative;
  overflow-y: auto;
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 2px 4px 8px 5px rgba(0, 0, 0, 0.2);
}
</style>
