<template>
  <v-card
    v-if="isPinboard && !isNotes && !isQuickstart"
    :class="$vuetify.breakpoint.mobile ? 'mb-2 ml-n6' : 'mx-2 mb-2'"
    height="100%"
    width="100%"
    flat
    :loading="loading"
  >
    <v-card-subtitle
      v-if="!loading && filteredCases.length===0 && filteredCaseForms.length===0 && filteredClients.length===0 && filteredTemplates.length===0 && filteredDocuments.length===0"
      class="center"
    >
      <v-icon
        color="primary"
      >
        mdi-pin-outline
      </v-icon>
      <span class="ml-2 mb-n1">{{ $t('expressions|no_pinned_content_available') }}</span>
    </v-card-subtitle>
    <v-card-text
      :class="$vuetify.breakpoint.mobile ? 'px-0' : ''"
    >
      <span
        v-if="filteredClients.length"
      >
        {{ $t('clients|clients') }}
      </span>
      <v-row
        v-if="!loading && filteredClients.length"
        :class="$vuetify.breakpoint.mobile ? '' : 'ma-1'"
        :justify="$vuetify.breakpoint.mobile ? 'center' : 'start'"
      >
        <v-col
          v-for="client in filteredClients"
          :key="client._id"
          :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
        >
          <ClientCardMini
            :key="client._id"
            :client="client"
          />
        </v-col>
      </v-row>

      <v-divider
        v-if="filteredClients.length"
        class="my-2"
      />
      <span
        v-if="filteredCases.length"
      >
        {{ $t('cases|cases') }}
      </span>
      <v-row
        v-if="!loading && filteredCases.length"
        :class="$vuetify.breakpoint.mobile ? '' : 'ma-1'"
        :justify="$vuetify.breakpoint.mobile ? 'center' : 'start'"
      >
        <v-col
          v-for="c in filteredCases"
          :key="c._id"
          :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
        >
          <CaseCardMini
            :case-data="c"
          />
        </v-col>
      </v-row>

      <v-divider
        v-if="filteredCaseForms.length"
        class="my-2"
      />
      <span v-if="filteredCaseForms.length">
        {{ $t('case_models|case_models') }}
      </span>
      <v-row
        v-if="!loading && filteredCaseForms.length"
        :class="$vuetify.breakpoint.mobile ? '' : 'ma-1'"
        :justify="$vuetify.breakpoint.mobile ? 'center' : 'start'"
      >
        <v-col
          v-for="cf in filteredCaseForms"
          :key="cf._id"
          :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
        >
          <CaseModelCardMini
            :case-form="cf"
            @removePin="removeCaseModel"
          />
        </v-col>
      </v-row>

      <v-divider
        v-if="filteredTemplates.length"
        class="my-2"
      />
      <span
        v-if="filteredTemplates.length"
      >
        {{ $t('templates|templates') }}
      </span>
      <v-row
        v-if="!loading && filteredTemplates.length"
        :class="$vuetify.breakpoint.mobile ? '' : 'ma-1'"
        :justify="$vuetify.breakpoint.mobile ? 'center' : 'start'"
      >
        <v-col
          v-for="t in filteredTemplates"
          :key="t._id"
          :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
        >
          <TemplateCardMini
            :template="t"
            @lavvira-template-preview="openPreviewDialog"
          />
        </v-col>
      </v-row>

      <v-divider
        v-if="filteredDocuments.length"
        class="my-2"
      />
      <span v-if="filteredDocuments.length">
        {{ $t('documents|documents') }}
      </span>
      <v-row
        v-if="!loading"
        :class="$vuetify.breakpoint.mobile ? '' : 'ma-1'"
        :justify="$vuetify.breakpoint.mobile ? 'center' : 'start'"
      >
        <v-col
          v-for="doc in filteredDocuments"
          :key="doc._id"
          :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
        >
          <DocumentCard
            :document-data="doc"
            @delete="deleteDocumentAction(doc)"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <FileView ref="fileView" />
    <LavviraTemplatePreview
      v-if="previewTemplateData"
      ref="LavviraTemplatePreview"
      :template-data="previewTemplateData"
    />
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CaseCardMini from '@/pages/Cases/CaseCardMini.vue'
import ClientCardMini from '@/pages/Clients/ClientCardMini.vue'
import DocumentCard from '../Documents/DocumentCard.vue'
import TemplateCardMini from '../TemplateEditor/TemplateCardMini.vue'
import CaseModelCardMini from '../CaseFormBuilder/CaseModelCardMini.vue'
import FileView from '../../components/dialogs/FileView.vue'
import { uniqBy } from '@/utils/utils'
import LavviraTemplatePreview from '@/components/dialogs/LavviraTemplatePreview.vue'

export default {
  components: {
    CaseCardMini,
    ClientCardMini,
    TemplateCardMini,
    DocumentCard,
    CaseModelCardMini,
    FileView,
    LavviraTemplatePreview
  },
  props: {
    isNotes: {
      type: Boolean,
      default: false
    },
    isQuickstart: {
      type: Boolean,
      default: false
    },
    isPinboard: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      sharedDocumentsWithMyAcc: [],
      sharedCaseForms: [],
      sharedTemplates: [],
      previewTemplateData: null,
      componentKey: 1
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      cases: state => state.cases.cases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases,
      caseForms: state => state.caseForms.caseForms,
      clients: state => state.clients.clients,
      templates: state => state.templates.templates,
      documents: state => state.documents.documents,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments,
      company: state => state.company.company,
      folders: state => state.documents.folders,
      companyFolders: state => state.companyDocuments.companyFolders,
      groupFolders: state => state.groupDocuments.groupFolders,
      lavviraTemplates: state => state.templates.lavviraTemplates
    }),
    filteredCases () {
      if (!this.account) return []
      let pinnedCases = []
      if (this.account.companyId && this.company) {
        const allCases = [...this.cases, ...this.companyCases, ...this.groupCases]
        for (let i = 0; i < allCases.length; i++) {
          const _case = allCases[i]
          const foundCase = _case.pinned.findIndex(id => id === this.account._id)
          if (foundCase !== -1) {
            pinnedCases.push(_case)
          }
        }
      } else {
        for (let i = 0; i < this.cases.length; i++) {
          const _case = this.cases[i]
          const foundCase = _case.pinned.findIndex(id => id === this.account._id)
          if (foundCase !== -1) {
            pinnedCases.push(_case)
          }
        }
      }
      const finalFilter = uniqBy(pinnedCases, '_id')
      return finalFilter
    },
    filteredClients () {
      let pinnedClients = []
      for (let i = 0; i < this.clients.length; i++) {
        const client = this.clients[i]
        const foundClientPin = client.pinned.findIndex(id => id === this.account._id)
        if (foundClientPin !== -1) {
          pinnedClients.push(client)
        }
      }
      return pinnedClients
    },
    filteredTemplates () {
      let pinnedTemplates = []
      for (let i = 0; i < this.templates.length; i++) {
        const template = this.templates[i]
        const foundTemplatePin = template.pinned.findIndex(id => id === this.account._id)
        if (foundTemplatePin !== -1) {
          pinnedTemplates.push(template)
        }
      }
      if (this.sharedTemplates.length) {
        for (let i = 0; i < this.sharedTemplates.length; i++) {
          const template = this.sharedTemplates[i]
          if (template.pinned.length > 0) {
            const foundPin = template.pinned.findIndex(id => id.toString() === this.account._id.toString())
            if (foundPin !== -1) {
              pinnedTemplates.push(template)
            }
          }
        }
      }
      if (this.lavviraTemplates.length) {
        for (let i = 0; i < this.lavviraTemplates.length; i++) {
          const template = this.lavviraTemplates[i]
          const foundTemplatePin = template.pinned.findIndex(id => id === this.account._id)
          if (foundTemplatePin !== -1) {
            pinnedTemplates.push(template)
          }
        }
      }
      const filteredPinnedTemplates = uniqBy(pinnedTemplates, '_id')
      return filteredPinnedTemplates
    },
    filteredCaseForms () {
      let pinnedCaseForms = []
      for (let i = 0; i < this.caseForms.length; i++) {
        const caseForm = this.caseForms[i]
        const foundPin = caseForm.pinned.findIndex(id => id === this.account._id)
        if (foundPin !== -1) {
          pinnedCaseForms.push(caseForm)
        }
      }
      if (this.sharedCaseForms.length) {
        for (let i = 0; i < this.sharedCaseForms.length; i++) {
          const sharedForm = this.sharedCaseForms[i]
          if (sharedForm.pinned.length > 0) {
            const foundPin = sharedForm.pinned.findIndex(id => id.toString() === this.account._id.toString())
            if (foundPin !== -1) {
              pinnedCaseForms.push(sharedForm)
            }
          }
        }
      }
      const uniqPinnedCaseForms = uniqBy(pinnedCaseForms, '_id')
      return uniqPinnedCaseForms
    },
    filteredDocuments () {
      let pinnedDocs = []
      for (let i = 0; i < this.documents.length; i++) {
        const document = this.documents[i]
        const foundPin = document.pinned.findIndex(id => id === this.account._id)
        if (foundPin !== -1) {
          pinnedDocs.push(document)
        }
      }
      if (this.companyDocuments.length) {
        for (let i = 0; i < this.companyDocuments.length; i++) {
          const doc = this.companyDocuments[i]
          const foundPin = doc.pinned.findIndex(id => id === this.account._id)
          if (foundPin !== -1) {
            pinnedDocs.push(doc)
          }
        }
      }
      if (this.groupDocuments.length) {
        for (let i = 0; i < this.groupDocuments.length; i++) {
          const doc = this.groupDocuments[i]
          const foundPin = doc.pinned.findIndex(id => id === this.account._id)
          if (foundPin !== -1) {
            pinnedDocs.push(doc)
          }
        }
      }
      if (this.folders.length) {
        for (let i = 0; i < this.folders.length; i++) {
          const folder = this.folders[i]
          for (let idx = 0; idx < folder.documents.length; idx++) {
            const document = folder.documents[idx]
            const foundPin = document.pinned.findIndex(id => id === this.account._id)
            if (foundPin !== -1) {
              pinnedDocs.push(document)
            }
          }
        }
      }
      if (this.companyFolders.length) {
        for (let i = 0; i < this.companyFolders.length; i++) {
          const cFolder = this.companyFolders[i]
          for (let cfIdx = 0; cfIdx < cFolder.documents.length; cfIdx++) {
            const cDocument = cFolder.documents[cfIdx]
            const foundPin = cDocument.pinned.findIndex(id => id === this.account._id)
            if (foundPin !== -1) {
              pinnedDocs.push(cDocument)
            }
          }
        }
      }
      if (this.groupFolders.length) {
        for (let i = 0; i < this.groupFolders.length; i++) {
          const gFolder = this.groupFolders[i]
          for (let gfIdx = 0; gfIdx < gFolder.documents.length; gfIdx++) {
            const gDocument = gFolder.documents[gfIdx]
            const foundPin = gDocument.pinned.findIndex(id => id === this.account._id)
            if (foundPin !== -1) {
              pinnedDocs.push(gDocument)
            }
          }
        }
      }
      const uniqDocs = uniqBy(pinnedDocs, '_id')
      return uniqDocs
    }
  },
  async created () {
    if (this.company) {
      this.loading = true
      for (let i = 0; i < this.company.groups.length; i++) {
        const group = this.company.groups[i]
        const index = group.groupMembers.findIndex(gm => gm._id === this.account._id)
        if (index !== -1) {
          // try {
          //   const { data } = await this.$axios.get(`/getSharedDocuments/${group._id}`)
          //   for (let i = 0; i < data.data.length; i++) {
          //     const sharedDoc = data.data[i]
          //     this.sharedDocumentsWithMyAcc.push(sharedDoc)
          //   }
          // } catch (error) {
          //   console.error(error, 'error')
          // }
          try {
            const { data } = await this.$axios.get(`/getSharedCaseForms/${group._id}`)
            for (let i = 0; i < data.data.length; i++) {
              const sharedCaseForm = data.data[i]
              this.sharedCaseForms.push(sharedCaseForm)
            }
          } catch (error) {
            console.error(error, 'error')
          }
          try {
            const { data } = await this.$axios.get(`/getSharedTemplates/${group._id}`)
            for (let i = 0; i < data.data.length; i++) {
              const sharedTemp = data.data[i]
              this.sharedTemplates.push(sharedTemp)
            }
          } catch (error) {
            console.error(error, 'error')
          }
        }
      }
      // try {
      //   const { data } = await this.$axios.get(`/getSharedDocuments/${this.account.companyId}`)
      //   for (let i = 0; i < data.data.length; i++) {
      //     const sharedDocCompany = data.data[i]
      //     this.sharedDocumentsWithMyAcc.push(sharedDocCompany)
      //   }
      // } catch (e) {
      //   console.error(e, 'err')
      // }
      try {
        const { data } = await this.$axios.get(`/getSharedCaseForms/${this.account.companyId}`)
        for (let i = 0; i < data.data.length; i++) {
          const sharedCaseFormCompany = data.data[i]
          this.sharedCaseForms.push(sharedCaseFormCompany)
        }
      } catch (error) {
        console.error(error, 'error')
      }
      try {
        const { data } = await this.$axios.get(`/getSharedTemplates/${this.account.companyId}`)
        for (let i = 0; i < data.data.length; i++) {
          const sharedTemp = data.data[i]
          this.sharedTemplates.push(sharedTemp)
        }
      } catch (error) {
        console.error(error, 'error')
      }
      this.loading = false
    }
  },
  methods: {
    ...mapActions({
      uploadDocument: 'documents/uploadDocument',
      updateDocument: 'documents/updateDocument',
      deleteDocument: 'documents/deleteDocument'
    }),
    openPreviewDialog (template) {
      this.previewTemplateData = template.templateData
      setTimeout(() => { this.$refs.LavviraTemplatePreview.lavviraTemplatePreviewDialog = true }, 300)
    },
    removeCaseModel (caseForm) {
      const index = this.sharedCaseForms.findIndex(cf => cf._id === caseForm._id)
      if (index !== -1) {
        this.sharedCaseForms.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped>
  .scroll {
     overflow-y: auto
  }
  .overlay {
    height: 100%;
    width: 100%;
  }
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
