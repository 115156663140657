import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import SelectToRemoveCaseModelCard from '../../CaseFormBuilder/SelectToRemoveCaseModelCard.vue'

export default {
  components: {
    SelectToRemoveCaseModelCard
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    })
  },
  methods: {
    ...mapActions({
      fetchCaseForms: 'caseForms/fetchCaseForms',
      updateCaseForm: 'caseForms/updateCaseForm',
      deleteCaseFormAction: 'caseForms/deleteCaseForm',
      addToast: 'toasts/addToast'
    }),
    translateCategoryIfNeeded (category) {
      if (category === 'none') return this.$t('common|not_available')
      else return category
    },
    availableCategories (currentCaseForm) {
      let categories = []
      if (currentCaseForm.sharedWith.accounts.length) {
        currentCaseForm.sharedWith.accounts.forEach(acc => {
          acc.myCaseModelsCategories.forEach(cat => {
            const newCat = {
              createdBy: acc.accountName ? acc.accountName : this.$t('mixed|account_no_longer_available'),
              value: cat.value
            }
            categories.push(newCat)
          })
        })
      }
      if (currentCaseForm.sharedWith.company.length) {
        currentCaseForm.sharedWith.company.forEach(company => {
          company.companyCaseModelsCategories.forEach(cat => {
            const newCat = {
              createdBy: company.companyData.companyName,
              value: cat.value
            }
            categories.push(newCat)
          })
        })
      }
      if (currentCaseForm.sharedWith.groups.length) {
        currentCaseForm.sharedWith.groups.forEach(group => {
          group.groupCaseModelsCategories.forEach(cat => {
            const newCat = {
              createdBy: group.groupName,
              value: cat.value
            }
            categories.push(newCat)
          })
        })
      }
      return categories
    },
    async changeCaseModelCategory (currentCaseForm, selectedCategory) {
      const payload = {
        category: selectedCategory
      }
      try {
        await this.updateCaseForm({ _id: currentCaseForm._id, payload })
      } catch (e) {
        console.error(e, 'error')
      }
    },
    async changeDescription (idx, currentCaseForm) {
      this.focus = null
      const element = document.getElementById(`case-model-description-${idx}`)
      const payload = {
        description: element.textContent
      }
      try {
        await this.updateCaseForm({ _id: currentCaseForm._id, payload })
      } catch (e) {
        console.error(e, 'error')
      }
    },
    previewCaseForm (caseForm) {
      EventBus.$emit('drawer-overview-case-model', caseForm)
    },
    viewCaseForm (caseForm) {
      this.$router.push({ path: `/models/${caseForm._id}/${this.workbench._id}` })
    },
    async deleteCaseForm (caseForm) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('case_models|warning_delete_model')
      })
      if (res) {
        try {
          await this.deleteCaseFormAction(caseForm)
        } catch (e) {
          console.error(e, 'e')
        }
      }
    },
    async removeCaseForm (caseForm) {
      const updateCaseForm = this.updateCaseForm
      const account = this.account
      const company = this.company
      const remove = this.$t('actions|remove')
      const from = this.$t('common|from')
      const select = this.$t('actions|select')
      const required = this.$t('fields|required')
      const myCaseModels = this.$t('case_models|my_case_models')
      SelectToRemoveCaseModelCard.props = {
        caseFormProp: {
          type: Object,
          default: () => ({ caseForm })
        },
        accountProp: {
          type: Object,
          default: () => ({ account })
        },
        companyProp: {
          type: Object,
          default: () => ({ company })
        },
        updateCaseFormFunction: {
          type: Function,
          default: updateCaseForm
        },
        $t_remove: {
          type: String,
          default: remove
        },
        $t_from: {
          type: String,
          default: from
        },
        $t_select: {
          type: String,
          default: select
        },
        $t_required: {
          type: String,
          default: required
        },
        $t_myCaseModels: {
          type: String,
          default: myCaseModels
        },
        onClick: Function
      }
      const dialogInstance = await this.$dialog.show(SelectToRemoveCaseModelCard, { onClick: this.closeDialogInstance.bind(this.closeDialogInstance) })
      this.dialogInstance = dialogInstance
    },
    closeDialogInstance () {
      this.dialogInstance.close()
    },
    useCaseForm (caseForm) {
      EventBus.$emit('dialog-add-case-from-case-model', caseForm)
    },
    async pinToDashboard (caseForm) {
      const payload = {
        pinned: this.account._id
      }
      try {
        await this.updateCaseForm({
          _id: caseForm._id,
          payload
        })
        this.addToast({
          title: 'Case model pinned to dashboard',
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: 'Case model pinned to dashboard failed',
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async removePinFromDashboard (caseForm) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('actions|remove_resource_from_pinboard')
      })
      if (res) {
        const payload = {
          removePinId: this.account._id
        }
        try {
          await this.updateCaseForm({
            _id: caseForm._id,
            payload
          })
          this.$emit('removePin', caseForm)
          this.addToast({
            title: 'Case model removed from dashboard',
            color: 'white',
            snackbarColor: 'success'
          })
        } catch (e) {
          this.addToast({
            title: 'Removing case model from the dashboard failed',
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    },
    getCaseFormSharedWithLength (caseForm) {
      return caseForm.sharedWith.accounts.length + caseForm.sharedWith.company.length + caseForm.sharedWith.groups.length
    }
  }
}
