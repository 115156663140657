<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :elevation="hover ? 6 : 2"
      :ripple="false"
      height="160px"
      :width="$vuetify.breakpoint.mobile ? '400px' : '300px'"
      class="mt-3"
      :class="$vuetify.breakpoint.mobile ? 'mx-auto' : ''"
      @click.stop="caseForm.shared && account.accountType !== 'god' ? previewCaseForm(caseForm, lavviraContent) : previewCaseForm(caseForm)"
    >
      <v-row class="ml-1">
        <v-col
          cols="10"
        >
          <div
            v-if="caseForm.name.length >= 20"
            class="thick"
          >
            <v-tooltip
              top
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ caseForm.name.slice(0, 15) + '...' }}
                </div>
              </template>
              <span>{{ caseForm.name }}</span>
            </v-tooltip>
          </div>
          <div
            v-else
            class="thick"
          >
            {{ caseForm.name }}
          </div>
          <div class="model-data mt-n1">
            <v-icon
              small
              color="primary"
            >
              mdi-briefcase-edit-outline
            </v-icon>
            {{ $t('case_models|case_model') }}
          </div>
          <div
            v-if="caseForm.category === 'none'"
            class="model-data"
          >
            {{ $t('common|category') }}: {{ $t('common|uncategorized') }}
          </div>
          <div
            v-else
            class="model-data"
          >
            {{ $t('common|category') }}: {{ caseForm.category }}
          </div>
          <div
            v-if="caseForm.description.length >= 20"
            class="model-data"
          >
            <v-tooltip
              top
              color="primary"
              max-width="400"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('common|description') }}: {{ caseForm.description.slice(0, 20) + '...' }}
                </div>
              </template>
              <span>{{ caseForm.description }}</span>
            </v-tooltip>
          </div>
          <div
            v-else
            class="model-data"
          >
            {{ $t('common|description') }}: {{ caseForm.description }}
          </div>
        </v-col>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              x-small
              v-bind="attrs"
              style="position: absolute; top: 15px; right: 25px;"
              v-on="on"
              @click.stop="removePinFromDashboard(caseForm)"
            >
              <v-icon>
                mdi-pin
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('actions|remove_from_pinboard') }}</span>
        </v-tooltip>
      </v-row>
      <v-btn
        text
        color="deep-purple accent-4"
        link
        x-small
        style="position: absolute; bottom: 7px; left: 10px;"
        @click.stop="viewCaseForm(caseForm)"
      >
        {{ $t('actions|open') }}
      </v-btn>
      <v-btn
        text
        icon
        x-small
        style="position: absolute; bottom: 8px; right: 25px;"
        color="red accent-4"
        @click.stop="getCaseFormSharedWithLength(caseForm) <= 1 ? deleteCaseForm(caseForm) : removeCaseForm(caseForm)"
      >
        <v-icon
          small
        >
          {{ icons.trash }}
        </v-icon>
      </v-btn>
      <v-col
        xs="2"
        md="1"
        lg="1"
      >
        <v-sheet
          color="primary"
          class="delete stripe-position mr-2 my-auto"
          min-width="9"
          max-width="10"
          height="80%"
        />
      </v-col>
    </v-card>
  </v-hover>
</template>

<script>
import caseModelMixin from './CaseModelMixins/caseModelMixin.js'
import {
  mdiTrashCanOutline,
  mdiChevronDown,
  mdiPinOutline
} from '@mdi/js'

export default {
  mixins: [caseModelMixin],
  props: {
    caseForm: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        trash: mdiTrashCanOutline,
        arrowDown: mdiChevronDown,
        pinOutline: mdiPinOutline
      },
      dialogInstance: null
    }
  },
  computed: {
    workbench () {
      return this.caseForm.workbenches[0]
    }
  }
}
</script>

<style scoped>
.actions-position {
  position: absolute;
  bottom: 0px;
}
.stripe-position {
  position: absolute;
  bottom: 15px;
}
.delete {
  position: absolute;
  right: 0;
}
.thick {
  font-size: 15px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
}
.model-data {
  font-size: 14px;
  font-weight: lighter;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 260px;
  overflow: hidden;
}
.model-data:hover {
  overflow: visible;
}
</style>
