import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { uniqBy } from '@/utils/utils'

export default {
  props: {
    client: {
      type: Object,
      default: null
    },
    actualTab: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      cases: state => state.cases.cases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases,
      account: state => state.account.account,
      company: state => state.company.company
    }),
    clientCases () {
      let filteredCases = []
      this.cases.forEach(c => {
        if (c.client && c.client._id === this.client._id) {
          filteredCases.push(c)
        }
      })
      this.companyCases.forEach(cc => {
        if (cc.client && cc.client._id === this.client._id) {
          filteredCases.push(cc)
        }
      })
      this.groupCases.forEach(gc => {
        if (gc.client && gc.client._id === this.client._id) {
          filteredCases.push(gc)
        }
      })
      const uniqueClientCases = uniqBy(filteredCases, '_id')
      return uniqueClientCases
    },
    clientName () {
      if (this.client.clientType === 'individual' && this.client.clientData.given_names && this.client.clientData.surname) {
        return (this.client.clientData.given_names.concat(' ' + this.client.clientData.surname)) || 'Not Set'
      } else if (this.client.clientType === 'individual' && this.client.clientData.given_names) {
        return this.client.clientData.given_names || 'Not Set'
      } else if (this.client.clientType === 'individual' && this.client.clientData.surname) {
        return this.client.clientData.surname || 'Not Set'
      }
      return this.client.clientData.company_name || 'Not Set'
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateClient: 'clients/updateClient',
      deleteClientAction: 'clients/deleteClient',
      cancelInvitationBackend: 'account/cancelInvitation'
    }),
    openOverview (client) {
      if (client.invitationFromProfessional && client.invitationFromProfessional === 'pending') return
      if (client.invitationFromProfessional && client.invitationFromProfessional === 'declined') return
      EventBus.$emit('drawer-overview-client', client)
    },
    routeToClient (clientId) {
      this.$router.push(`/clients/${clientId}`)
    },
    routeToCase (caseId) {
      this.$router.push(`/cases/${caseId}`)
    },
    async cancelInvitation (client) {
      const _id = this.account._id
      let invitationSent = null
      const res = await this.$dialog.confirm({
        text: this.$t('warning|you_want_to_cancel_invitation'),
        title: this.$t('common|warning')
      })
      if (!res) return
      if (this.actualTab && this.actualTab.isMyTab) {
        invitationSent = this.account.invitationsSent.find(inv => inv.dummyClient === client._id)
      } else if (this.actualTab && this.actualTab.isCompanyTab) {
        invitationSent = this.company.invitationsSent.find(inv => inv.dummyClient === client._id)
      } else if (this.actualTab && this.actualTab.isGroupTab) {
        invitationSent = this.actualTab.group.invitationsSent.find(inv => inv.dummyClient === client._id)
      }
      const payload = invitationSent
      try {
        await this.cancelInvitationBackend({ _id, payload })
      } catch (error) {
        console.error(error)
        this.addToast({
          title: 'Canceling the invitation was not successful',
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async deleteClient (client) {
      const res = await this.$dialog.confirm({
        text: this.$t('clients|warning_delete_client'),
        title: this.$t('common|warning')
      })
      if (!res) return
      let payload
      if (this.actualTab.isMyTab) {
        payload = {
          isMyTab: true
        }
      }
      if (this.actualTab.isCompanyTab) {
        payload = {
          isCompanyTab: true,
          companyId: this.actualTab.company._id
        }
      }
      if (this.actualTab.isGroupTab) {
        payload = {
          isGroupTab: true,
          groupId: this.actualTab.group._id
        }
      }
      try {
        await this.deleteClientAction({ _id: client._id, payload })
      } catch (e) {
        console.error(e, 'e')
      }
    },
    async pinToDashboard (client) {
      const payload = {
        payload: {
          setPin: this.account._id
        }
      }
      try {
        await this.updateClient({
          _id: client._id,
          payload
        })
        this.addToast({
          title: this.$t('message|client_pin_dashboard_success'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('message|client_pin_dashboard_fail'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async removePinFromDashboard (client) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure'),
        title: this.$t('common|warning')
      })
      if (!res) return
      if (res) {
        const payload = {
          payload: {
            removePin: this.account._id
          }
        }
        try {
          await this.updateClient({
            _id: client._id,
            payload
          })
          this.addToast({
            title: this.$t('message|client_remove_dashboard_success'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch (e) {
          this.addToast({
            title: this.$t('message|client_remove_dashboard_fail'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    }
  }
}
