<template>
  <v-card
    height="200"
    class="pa-2"
  >
    <v-card-title>
      {{ $t_remove }} {{ caseFormProp.caseForm.name }} {{ $t_from }}:
    </v-card-title>
    <v-select
      v-model="selectedItem"
      :items="items"
      outlined
      :label="$t_select"
      dense
      required
      :rules="[(v) => !!v || $t_required]"
      :clearable="true"
      class="mt-2 mx-2"
    />
    <v-card-actions>
      <v-spacer />
      <v-btn
        small
        rounded
        color="primary"
        :disabled="!selectedItem"
        min-width="100"
        @click="removeCaseFrom(selectedItem); onClick()"
      >
        {{ $t_remove }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  data () {
    return {
      selectedItem: ''
    }
  },
  computed: {
    items () {
      let itemsArray = []
      const _case = this.caseFormProp.caseForm
      const account = this.accountProp.account
      if (_case && _case.sharedWith.accounts.length) {
        const index = _case.sharedWith.accounts.findIndex(acc => acc._id === account._id)
        if (index !== -1) {
          itemsArray.push(this.$t_myCaseModels)
        }
      }
      if (_case && _case.sharedWith.company.length) {
        itemsArray.push(_case.sharedWith.company[0].companyData.companyName)
      }
      if (_case && _case.sharedWith.groups.length) {
        _case.sharedWith.groups.forEach(group => {
          const participationIndex = group.groupMembers.findIndex(memberId => memberId === account._id)
          if (participationIndex !== -1) {
            itemsArray.push(group.groupName)
          }
        })
      }
      return itemsArray
    }
  },
  methods: {
    async removeCaseFrom (selectedItem) {
      const _case = this.caseFormProp.caseForm
      const company = this.companyProp.company
      const account = this.accountProp.account
      const updateCaseForm = this.updateCaseFormFunction
      let payload
      company.groups.forEach(group => {
        if (group.groupName === selectedItem) {
          payload = {
            groupId: group._id,
            removeCaseModel: true,
            removeFromShared: group._id,
            isCompany: false,
            isGroup: true,
            isMyTab: false
          }
        }
      })
      if (selectedItem === company.companyData.companyName) {
        payload = {
          companyId: company._id,
          removeCaseModel: true,
          removeFromShared: company._id,
          isCompany: true,
          isGroup: false,
          isMyTab: false
        }
      }
      if (selectedItem === this.$t_myCaseModels) {
        payload = {
          accId: account._id,
          removeCaseModel: true,
          removeFromShared: account._id,
          isCompany: false,
          isGroup: false,
          isMyTab: true
        }
      }
      try {
        await updateCaseForm({
          _id: _case._id,
          payload
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
